<template>
	<v-container>
		<v-card v-if="article" class="mx-auto">
			<v-img
				class="white--text align-end"
				:src="article.image ? `${article.image}` : ''"
				max-height="600"
				contain
			>
				<!-- <v-card-subtitle v-for="(category, i) in article.categories" :key="i">
					<div>{{ category }}</div>
				</v-card-subtitle> -->
			</v-img>

			<v-card-title class="text--primary pb-2">{{
				article.title
			}}</v-card-title>

			<v-card-text v-html="article.content" class="text--primary">
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" dark @click="$router.go(-1)">
					{{ $t('back') }}
				</v-btn>
			</v-card-actions>
		</v-card>

		<!-- Loader -->
		<div v-if="isLoading" class="text-center">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</div>
	</v-container>
</template>

<script>
export default {
	name: 'Article',

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			isLoading: false,
			article: null,
		};
	},
	async mounted() {
		this.isLoading = true;

		try {
			const res = await this.$http.get(
				`${this.url}/articles/${this.$route.params.slug}`,
			);

			this.article = res.data.data;

			// In mongo HTML is saved with &lt; isntead < and that breaks v-html directive
			// This will fix it for now
			// this.article.fixedContent = this.article.sanitizedContent.replace(
			// 	/&lt;/g,
			// 	'<',
			// );
		} catch (error) {
			console.error(error);
		} finally {
			this.isLoading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
